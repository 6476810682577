<template>
    <div>
        <v-row v-if="horizontalRendering">
            <v-col cols="2" v-for="field in fields" :key="field.value">                
                <v-card-text class="pt-0 pl-0 pr-0">
                    <advanced-grid-filter :dynamic="dynamic" :field="field" :editable="false" v-model:filtervalue="field.criteria" @delete="onDelete" />
                </v-card-text>
            </v-col>
            <v-col cols="2">                
                <v-card-text class="pt-4 pl-4 pr-4 pb-4 rounded smoothborder h-full" style="display: flex; align-items: center; justify-content: center; flex-direction: column">
                    <v-autocomplete                        
                        v-model="attributeToAdd"
                        :items="attributeOptions"
                        label="Attribute"      
                        outlined       
                        dense                                                      
                    ></v-autocomplete>
                    <v-btn outlined @click="addCriteria" color="primary">   
                            <v-icon class="pr-2">mdi-plus</v-icon>                            
                            Add<br/>Condition
                    </v-btn>
                </v-card-text>
            </v-col>
        </v-row>
        <v-card-text v-if="!horizontalRendering" class="pt-0 pl-0 pr-0 d-flex">
            <v-btn class="flex -mr-5" small color="primary" dark @click="search" v-if="searchButtonVisible">
                Search
            </v-btn>
            <!-- <v-btn class="flex" small color="primary" outlined>
                Clear
            </v-btn> -->
        </v-card-text>        
        <v-card-text v-if="!horizontalRendering" class="pt-0 pl-0 pr-0" v-for="field in fields" :key="field.value">
            <advanced-grid-filter :dynamic="dynamic" :field="field" :editable="false" v-model:filtervalue="field.criteria" @delete="onDelete" />
        </v-card-text>  
    </div>
</template>

<script>
import AdvancedGridFilter from "@/components/core/AdvancedGridFilter"

export default {
    name: 'AdvancedGridFiltersList',
    components: {
        "advanced-grid-filter": AdvancedGridFilter
    },
    props: {
        fields: Array,
        dynamic: Boolean,
        filtervalue: Object,
        attributeOptions: {
            default: [],
            type: Array
        },
        searchButtonVisible: {
            default: true,
            type:  Boolean,
        },
        horizontalRendering: {
            default: false,
            type: Boolean
        }
    },
    model: {
      prop: 'filtervalue',
      event: 'update'
    },
    data() {
        return {
            select_field: "",   
            attributeToAdd: null,         
        }
    },
    methods: {
        search() {
            let criteria = this.fields.map(i => { return { name: i.value, operator: i.criteria.operator, search_value: i.criteria.search_value, type: i.type  } });    
            let criteriaQueryString = "&";
            for(let i=0; i<criteria.length; i++) {
                const c = criteria[i];     
                if(c.name && c.operator && c.search_value) {
                    criteriaQueryString += c.name + "[type]=" + c.type
                    criteriaQueryString += "&" + c.name + "[operator]=" + c.operator
                    criteriaQueryString += "&" + c.name + "[search_value]=" + encodeURIComponent(c.search_value)                    
                    criteriaQueryString += "&"
                }                
            }            
            this.$emit('update', {criteria, qs: criteriaQueryString})
            this.$emit('search', {criteria, qs: criteriaQueryString})
            //console.log("\n++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++\n" + JSON.stringify(this.fields, null, 2) + "\n++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++\n");
        },
        onDelete(nameToDelete) {            
            this.$emit('delete',  nameToDelete);            
        },
        addCriteria() {            
            this.$emit('addcriteria', this.attributeToAdd);   
            this.attributeToAdd = null;         
        }
    }
}
</script>

<style lang="scss" scoped>
    .smoothborder {
        border: 1px lightgray solid;
    }
</style>