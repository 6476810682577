<template>
  <div>
    <!-- <div>
      <h2>Search and add a pin</h2>
    </div>
    <br> -->
    <!-- {{JSON.stringify(pinsCoords)}} -->
    <!-- {{JSON.stringify(pois)}} -->
    <GmapMap
      :center='center'
      :zoom="zoom"
      style="width:100%;  height: 300px;"      
    >
        <GmapMarker
            :key="index"
            v-for="(m, index) in pinsCoords"
            :position="m.position"
            :draggable="draggable"
            @dragend="dragged"
            @click="center=m.position"
            ref="mymap"
        />
    </GmapMap>
  </div>
</template>

<script>
export default {  
  name: 'GoogleMap',  
  props: {
    pois: {
      type: Array,
      default: ()=>[],
    },
    draggable: {
      type: Boolean,
      default: ()=>false,
    },
    zoom: {
      type: Number,
      default: ()=>12
    }
  },
  data() {
    return {
        center: { lat: 37.9842, lng: 23.7281 },
        //pinsCoords: [{ lat: 37.9842, lng: 23.7281 }],
        
        // [
        //         {position: { lat: 38.03395168814914, lng: 23.74479997873596 }, fillColor: "black" },
        //         {position: { lat: 38.096211703562744, lng: 23.82383093781674 }, fillColor: "black" },
        // ],      
        googlemapsobject: null,
      //38.096211703562744, 23.82383093781674
    }
  },
  computed: {
    pinsCoords() {
        return [...this.pois];
    },
    markers() {
        // var LatLngList = this.pinsCoords.map(p => {
        //     return new google.maps.LatLng (p.position.lat,p.position.lng);
        // });
        // //new Array (new google.maps.LatLng (52.537,-2.061), new google.maps.LatLng (52.564,-2.017));
        // let bounds = new google.maps.LatLngBounds ();
        // for (var i = 0, LtLgLen = LatLngList.length; i < LtLgLen; i++) {
        //     //  And increase the bounds to take this point
        //     bounds.extend (LatLngList[i]);
        // }
        // this.$refs.mymap.fitBounds (bounds);
        return this.pinsCoords.map(p => {
            return {...p};
        });
    }
  },

  watch: {
    pinsCoords: {
      handler(newVal, oldVal) {
        if(this.pinsCoords.length == 1) {
                this.center = {
                    lat: this.pinsCoords[0].position.lat,
                    lng: this.pinsCoords[0].position.lng,
                };
            } else {                
                if(this.googlemapsobject && this.$refs.mymap) {            
                var LatLngList = this.pinsCoords.map(p => {
                    return new this.googlemapsobject.LatLng(p.position.lat,p.position.lng);
                });
                //new Array (new google.maps.LatLng (52.537,-2.061), new google.maps.LatLng (52.564,-2.017));
                let bounds = new this.googlemapsobject.LatLngBounds();
                for (var i = 0, LtLgLen = LatLngList.length; i < LtLgLen; i++) {
                    //  And increase the bounds to take this point
                    bounds.extend(LatLngList[i]);
                }
                ///////this.$refs.mymap.$mapObject.fitBounds(bounds);
                this.$refs.mymap[0].$map.fitBounds(bounds);
            }
        } 
      },
      immediate: true
    },
    pinsCoords2() {
        //console.log("RECALC MARKERS");
        if(this.pinsCoords.length == 1) {
                this.center = {
                    lat: this.pinsCoords[0].position.lat,
                    lng: this.pinsCoords[0].position.lng,
                };
            } else {                
                if(this.googlemapsobject && this.$refs.mymap) {            
                var LatLngList = this.pinsCoords.map(p => {
                    return new this.googlemapsobject.LatLng(p.position.lat,p.position.lng);
                });
                //new Array (new google.maps.LatLng (52.537,-2.061), new google.maps.LatLng (52.564,-2.017));
                let bounds = new this.googlemapsobject.LatLngBounds();
                for (var i = 0, LtLgLen = LatLngList.length; i < LtLgLen; i++) {
                    //  And increase the bounds to take this point
                    bounds.extend(LatLngList[i]);
                }
                ///////this.$refs.mymap.$mapObject.fitBounds(bounds);
                this.$refs.mymap[0].$map.fitBounds(bounds);
            }
        } 
    }
  },
  mounted() {
    this.geolocate();

    //this.$refs.mymap.$mapPromise.then(() => { alert("loaded") });
    let self = this;

    this.$gmapApiPromiseLazy({}).then(() => {
        this.googlemapsobject = google.maps;





        // if(self.pinsCoords.length == 1) {
        //         self.center = {
        //             lat: self.pinsCoords[0].position.lat,
        //             lng: self.pinsCoords[0].position.lng,
        //         };
        //     } else {                
        //         if(self.googlemapsobject && self.$refs.mymap) {            
        //         var LatLngList = self.pinsCoords.map(p => {
        //             return new this.googlemapsobject.LatLng(p.position.lat,p.position.lng);
        //         });
        //         //new Array (new google.maps.LatLng (52.537,-2.061), new google.maps.LatLng (52.564,-2.017));
        //         let bounds = new this.googlemapsobject.LatLngBounds();
        //         for (var i = 0, LtLgLen = LatLngList.length; i < LtLgLen; i++) {
        //             //  And increase the bounds to take this point
        //             bounds.extend(LatLngList[i]);
        //         }
        //         ///////this.$refs.mymap.$mapObject.fitBounds(bounds);

        //         //self.$refs.mymap[0].$map.fitBounds(bounds);
        //         self.$refs.mymap[0].fitBounds(bounds);
        //     }
        // } 
     });


    this.$refs.mymap[0].$mapPromise.then((map) => {


      if(self.pinsCoords.length == 1) {
              self.center = {
                  lat: self.pinsCoords[0].position.lat,
                  lng: self.pinsCoords[0].position.lng,
              };
          } else {                
              if(self.googlemapsobject && map) {            
              var LatLngList = self.pinsCoords.map(p => {
                  return new this.googlemapsobject.LatLng(p.position.lat,p.position.lng);
              });
              //new Array (new google.maps.LatLng (52.537,-2.061), new google.maps.LatLng (52.564,-2.017));
              let bounds = new this.googlemapsobject.LatLngBounds();
              for (var i = 0, LtLgLen = LatLngList.length; i < LtLgLen; i++) {
                  //  And increase the bounds to take this point
                  bounds.extend(LatLngList[i]);
              }
              ///////this.$refs.mymap.$mapObject.fitBounds(bounds);

              //self.$refs.mymap[0].$map.fitBounds(bounds);
              map.fitBounds(bounds);
          }
      } 



      //map.panTo({lat: 1.38, lng: 103.80})
    });
  },
  
  methods: {
    geolocate: function() {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
    dragged: function(coords) {
      this.$emit('ondrag', coords.latLng);      
    }
  },
}
</script>