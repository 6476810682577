<template>
  <div>
        <v-card-text class="mt-0 pt-0 mb-0 pb-0">        
            <v-row >
                <v-col sm="12" md="2" class="pt-5"><span>Selected ({{value.selectedCities ? value.selectedCities.length : 0}})</span></v-col>
                <v-col sm="12" md="10" style="vertical-align: middle;">
                    <v-chip-group column>
                            <v-chip color="#EB8E45" text-color="white" v-for="(c, index) in value.selectedCities" :key="c+'_sel'+index" @click="deselectCity(c)">                                            
                                {{c}}
                                <v-icon right>
                                    mdi-close-circle-outline
                                </v-icon>
                            </v-chip>                                                                       
                    </v-chip-group>
                </v-col>
            </v-row> 
        </v-card-text>
        <v-card-text class="mt-0 pt-0 mb-0 pb-0" v-if="nearbyCities.length>0">        
            <v-row >
                <v-col sm="12" md="2" class="pt-5"><span>Nearby Suggestions:</span></v-col>
                <v-col sm="12" md="10" style="vertical-align: middle;">
                    <v-chip-group column>
                            <v-chip outlined color="#EB8E45" small text-color="#EB8E45" v-for="(c,index) in nearbyCities" :key="c+'_rec'+index" @click="selectCity(c.city)">                                            
                                {{c.city}}
                                <v-icon right>
                                    mdi-map-marker-plus
                                </v-icon>
                            </v-chip>                                                                       
                    </v-chip-group>
                </v-col>
            </v-row> 
        </v-card-text>
        <v-card-text class="mt-0 pt-0 mb-0 pb-0">        
            <v-row >
                <v-col sm="12" md="2" class="pt-5"><span>Prefecture ({{selectedPrefecturesIndexes.length}})</span></v-col>
                <v-col sm="12" md="10" style="vertical-align: middle;">
                    <v-sheet
                        class="mx-auto"
                        max-width="700"
                    >
                        <v-slide-group
                        multiple
                        show-arrows
                        v-model="selectedPrefecturesIndexes"                                    
                        >
                        <v-slide-item
                            v-for="n in prefectures"
                            :key="n"
                            v-slot="{ active, toggle }"
                        >
                            <v-btn
                            class="mx-2"
                            :input-value="active"
                            active-class="purple white--text"
                            depressed
                            rounded
                            @click="toggle"
                            small
                            >
                            {{ n }}
                            </v-btn>
                        </v-slide-item>
                        </v-slide-group>
                    </v-sheet>
                </v-col>
            </v-row>      
            <v-row>
                <v-col sm="6" class="pt-10"><span>Click on the City/Cities you want to select.</span></v-col>
                <v-col sm="6">
                    <v-text-field
                        v-model="quicksearch"
                        append-icon="mdi-magnify"
                        label="Filter by city name..."
                        single-line
                        hide-details     
                        class="col-5-"   
                        @input="triggerQuicksearch"
                        clearable
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="12">                                
                    <v-chip-group outlined column style="height: 100px;">
                        <vue-perfect-scrollbar
                            :settings="{suppressScrollX: true, wheelPropagation: false}"
                            class="h-100 rtl-ps-none ps scroll"
                            style="height: 100%;"
                        >
                            <v-chip outlined small v-for="(c,index) in shownCities" :key="c.city+'_'+index" @click="selectCity(c.city)">                                            
                                {{c.city}}
                                <v-icon right>
                                    mdi-plus
                                </v-icon>
                            </v-chip>
                        </vue-perfect-scrollbar>                                    
                    </v-chip-group>
                </v-col>                            
            </v-row>    
            <div class="mb-4 pb-4">
                <google-map :pois="pois" />
            </div>         
        </v-card-text>   
    </div>
</template>

<script>
import { api } from "src/api/index";
import GoogleMap from "@/components/core/GoogleMap";

export default {
    components: {
        GoogleMap,
    },
    model: {
        prop: "value",
        event: "change"
    },

    props: {        
        value: {
            type: Object,
            default: () => ({
                selectedCities: [],                
                selectedPrefectures: []
            }),
        },
        prefectures: {
            type: Array,
            default: () => [],
        },
        cities: {
            type: Array,
            default: () => [],
        }
    },
    data() {
        return {            
            quicksearch: '',
            selectedPrefecturesIndexes: [],


            // prefectures: [],
            // cities: [],
            nearbyCities: [],
            operationYears: [...Array(40).keys()].map(i=>2023-i),
        }                
    },

    computed: {
        shownCities() {
            let self = this;
            let prefs = [...this.value.selectedPrefectures];//this.value.selectedPrefectures.map(i=>{ return this.prefectures[i] }) || [];
            return this.cities.filter(c=> {
                if(prefs && prefs.length > 0) {
                    if(self.quicksearch) {
                        return (c.city.includes(self.quicksearch.toUpperCase())
                        &&
                            !self.value.selectedCities.includes(c.city) )
                        &&  prefs.includes(c.admin_name)                    
                        ;
                    }
                    else {
                        return !self.value.selectedCities.includes(c.city) &&  prefs.includes(c.admin_name)   ;
                    } 
                }
                else {
                    if(self.quicksearch) {
                        return (c.city.includes(self.quicksearch.toUpperCase())
                        &&
                            !self.value.selectedCities.includes(c.city) )                    
                        ;
                    }
                    else {
                        return !self.value.selectedCities.includes(c.city);
                    } 
                }
                               
            }).slice(0, 20);
        },
        pois() {
            let self=this;
            // return [
            //     {position: { lat: 38.03395168814914, lng: 23.74479997873596 }, fillColor: "black" },
            //     {position: { lat: 38.096211703562744, lng: 23.82383093781674 }, fillColor: "black" },
            // ];
            return self.cities.filter(c=> {
                return self.value.selectedCities.includes(c.city)
            }).map(c=> { return {position: {lat: parseFloat(c.lat), lng: parseFloat(c.lng)}}});
        }
    },

    watch: {
        'value.selectedCities': async function (newVal, oldVal) {
            //const citiesToSend = this.selectedCities;
            const rsp = await api.hud.nearbycities(this.value.selectedCities);
            this.nearbyCities.splice(0);
            this.nearbyCities.push(...rsp.data);
        },
        // async selectedCities() {
        //     //const citiesToSend = this.selectedCities;
        //     const rsp = await api.hud.nearbycities(this.value.selectedCities);
        //     this.nearbyCities.splice(0);
        //     this.nearbyCities.push(...rsp.data);
        // }  
        selectedPrefecturesIndexes: {
            deep: true,
            handler() {                
                this.value.selectedPrefectures.splice(0);
                this.value.selectedPrefectures.push(...this.selectedPrefecturesIndexes.map(i=>this.prefectures[i]));
            }
        }, 
    },

    methods: {
        emit() {
            this.$emit('change', this.value);
        },
        selectCity(cityToSelect) {
            this.value.selectedCities.push(cityToSelect);
            this.quicksearch = null;
        },
        deselectCity(cityToDeselect) {
            this.value.selectedCities.splice(this.value.selectedCities.indexOf(cityToDeselect),1);
            //TRIGGER MODEL CHANGE
        },
        triggerQuicksearch() {
            // if (this.quicksearch && this.quicksearch.length > 2) {
            //     this.fetchGridData();
            // }      
            //this.fetchGridData();       
        }
    },

    mounted() {
        let self=this;
        this.selectedPrefecturesIndexes.push(
            ...self.value.selectedPrefectures.map(p=> { return self.prefectures.indexOf(p) })
        )
    }
}
</script>

<style>

</style>