<template>
    <div v-if="!editable">
        <v-card-text class="pt-0 pl-0 pr-0 pb-0 rounded" :class="!editMode ? 'smoothborder' : ''">
            <v-select     
                v-if="editMode"                                                 
                v-model="select_field"
                :items="[field]" 
                label="Field"  
                :readonly="!editable"    
                :append-icon="''" 
                hide-details
                single-line              
                dense      
                style="font-weight: bold"            
            />
            <v-select    
                v-if="editMode"
                v-model="select_operator"
                :items="operators.filter(o => o.type.includes(field.type))" 
                :readonly="field.type === 'list'"
                @input="onInput"
                label="Operator"                 
                hide-details
                single-line                       
                dense                 
            />
            <v-text-field 
                v-if="editMode && field.type==='text'"
                v-model="search_value"
                :outlined="false"                
                :clearable="true"
                hide-details                                       
                dense
                class="pt-4"
                :persistent-hint="false"
                label='(Type Filter Value)'
                @input="onInput"                
            />
            <v-select                         
                v-if="editMode && field.type === 'list'"
                v-model="search_value"
                :items="field.listItems"                 
                label="Select List Item" 
                @input="onInput"
                hide-details
                single-line                       
                dense       
                :clearable="true"                      
            />
            <!-- <v-spacer /> -->
            <!-- <v-numeric 
                v-if="field.type==='number'"
                style="margin-top: 16px !important;"
                text 
                :outlined="false"                
                :clearable="true"
                dense
                :persistent-hint="false"
                v-model="search_value"
                :label= " (select_operator==='betweenexcl' || select_operator==='betweenincl') ? '(From...)' : '(Type Filter Value)' "  
                @input="onInput"   
                useCalculator="false"  
                calcIcon=""           
                >
                
            </v-numeric> -->
            <v-text-field 
                v-if="editMode && field.type==='number'"
                v-model.number="search_value"
                :outlined="false"                
                :clearable="true"
                hide-details                                       
                dense
                class="pt-4"
                :persistent-hint="false"
                :label= " (select_operator==='betweenexcl' || select_operator==='betweenincl') ? '(From...)' : '(Type Filter Value)' "
                @input="onInput"                  
                type="number"                             
            />
            <!-- <v-numeric 
                v-if="field.type==='number' && (select_operator==='betweenexcl' || select_operator==='betweenincl')"
                text 
                outlined 
                v-model="search_valueTo"
                label='(To...)'
                @input="onInput"                
                >
                
            </v-numeric> -->
            <v-text-field 
                v-if="editMode && field.type==='number' && (select_operator==='betweenexcl' || select_operator==='betweenincl')"
                v-model.number="search_valueTo"
                :outlined="false"                
                :clearable="true"
                hide-details                                       
                dense
                class="pt-4"
                :persistent-hint="false"
                label='(To...)'
                @input="onInput"  
                type="number"                             
            />


            <!-- <v-menu
                v-if="field.type==='date'"
                ref="menuDatepicker"
                v-model="menuDatepicker"
                :close-on-content-click="false"
                :return-value.sync="search_date"
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
                <template v-slot:activator="{on, attrs}">
                    <v-text-field
                        label='(Click to Choose a Date)'
                        v-model="search_date"                                              
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                    ></v-text-field>
                </template>
                <v-date-picker v-model="search_date" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="menuDatepicker = false"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.menuDatepicker.save(search_date)"
                    >
                        OK
                    </v-btn>
                </v-date-picker>
            </v-menu> -->

            <v-menu
                v-if="editMode && field.type==='date'"
                ref="menuDatepicker"
                v-model="menuDatepicker"
                :close-on-content-click="false"
                
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
                <template v-slot:activator="{on, attrs}">
                    <v-text-field
                        label='(Click to Choose a Date)'
                        v-model="search_date_formatted"                                              
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hide-details    
                        :clearable="true"    
                        @click:clear="onClear"                
                    ></v-text-field>
                </template>
                <v-date-picker v-model="search_date" no-title scrollable @input="onInput" @emptied="onInput">                    
                </v-date-picker>
            </v-menu>

            <v-menu 
                v-if="editMode && field.type==='date' && (select_operator==='betweenexcl' || select_operator==='betweenincl') "
                ref="menuDatepickerTo"
                v-model="menuDatepickerTo"
                :close-on-content-click="false"                
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
                <template v-slot:activator="{on, attrs}">
                    <v-text-field
                        label='(To...)'
                        v-model="search_dateTo"                                              
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        :clearable="true"    
                        @click:clear="onClear" 
                    ></v-text-field>
                </template>
                <v-date-picker v-model="search_dateTo" no-title scrollable @input="onInput">                    
                </v-date-picker>
            </v-menu>
            <div v-if="editMode && dynamic" class="d-flex justify-space-between py-2">
                <v-icon color="primary" @click="onDelete">mdi-trash-can-outline</v-icon>
                <v-icon color="success" @click="(e) => {editMode=false; onInput(e)}">mdi-check-circle</v-icon>
            </div>
            <div v-if="!editMode" class="d-flex justify-space-between pt-2 pb-1 px-3">                                
                <span style="font-weight: bold;">{{field.text}}</span>
                <span>{{getOptionDescription(operators, select_operator)}}</span>                
            </div>
            <div v-if="!editMode" class="d-flex justify-space-between pb-2 px-3">   
                <span>{{field.type !== 'list' ? search_value : getOptionDescription(field.listItems, search_value)}}</span>             
                <v-icon color="secondary" style="margin-right: 10px;" @click="(e) => {editMode=true; onInput(e)}">mdi-pencil-box-outline</v-icon>
            </div>
        </v-card-text>     
    </div> 
    <div v-else>
    </div> 
</template>

<script>
import {parseDate, formatDate} from '@/core/date-utils.js'

export default {
    name: 'AdvancedGridFilter',
    props: {
        field: Object,
        filtervalue: Object,
        editable: Boolean,
        dynamic: Boolean
    },
    model: {
      prop: 'filtervalue',
      event: 'update'
    },
    data() {        
        return {            
            editMode: (this.field.hasOwnProperty('criteria') && this.field.criteria.hasOwnProperty('editMode') ? this.field.criteria.editMode : true),
            menuDatepicker: false,
            menuDatepickerTo: false,
            select_field: this.field,
            select_operator: (this.field.type === 'list') ? "equals" :                                 
                                (this.field.type === 'text') ? "contains" : ""            
            ,
            search_value: (this.field.hasOwnProperty('criteria') && this.field.criteria.hasOwnProperty('search_value') ? this.field.criteria.search_value : ""),
            search_valueTo: "",
            search_date: "", //new Date().toISOString().substr(0, 10),
            search_date_formatted: "", //new Date().toISOString().substr(0, 10),
            search_dateTo: "", //new Date().toISOString().substr(0, 10),                        
            search_dateTo_formatted: "", //new Date().toISOString().substr(0, 10),                        
            operators: [
                {
                    value: "contains",
                    text: "Contains",
                    type: ["text"]
                },                        
                {
                    value: "startswith",
                    text: "Starts with",
                    type: ["text"]
                },
                {
                    value: "endswith",
                    text: "Ends with",
                    type: ["text"]
                },
                {
                    value: "equals",
                    text: "Equals =",
                    type: ["text", "number", "date", "list"]
                },
                {
                    value: "lessthan",
                    text: "Less Than <",
                    type: ["number", "date"]
                },
                {
                    value: "lessthanorequal",
                    text: "Less Than Or Equal <=",
                    type: ["number", "date"]
                },
                {
                    value: "morethan",
                    text: "More Than >",
                    type: ["number", "date"]
                },
                {
                    value: "morethanorequal",
                    text: "More Than Or Equal >=",
                    type: ["number", "date"]
                },
                {
                    value: "betweenexcl",
                    text: "Between Excl. [ ]",
                    type: ["number", "date"]
                },
                {
                    value: "betweenincl",
                    text: "Between Incl. ( )",
                    type: ["number", "date"]
                },
            ]
        }
    },
    mounted() {
        this.select_field = this.field;
    },
    watch: {
      search_date(val) {        
          console.log("SEARCH DATE CALLED");
        // const t = this.formatDate(this.search_date);
        // console.log(t);
        this.search_date_formatted = formatDate(this.search_date);
      },
    },
    methods: {        
        getOptionDescription(optionList, optionValue){
            let matchedItem = optionList.find(o=>o.value===optionValue);
            let rslt = optionValue
            if(matchedItem) {
                rslt = matchedItem.text;
            }
            return rslt;
        },
        parseDate(d) {
            return parseDate(d);
        },
        formatDate(d) {
            return formatDate(d);
        },
        onDelete() {                      
            this.$emit('delete',  this.select_field.value);
        },
        onClear(event) {                    
            this.menuDatepicker = false;
            this.menuDatepickerTo = false;

            if(this.field.type === 'text') {
                this.search_value = "";
                this.search_valueTo = "";
                this.$emit('update',  {search_value: this.search_value, operator: this.select_operator})   
            }  
             if(this.field.type === 'list') {
                 this.search_value = "";
                 this.search_valueTo = "";
                this.$emit('update',  {search_value: this.search_value, operator: this.select_operator})   
            }           
            if(this.field.type === 'number') {
                this.search_value = "";
                this.search_valueTo = "";
                this.$emit('update', {search_value: this.search_value, operator: this.select_operator})   
            }
            if(this.field.type === 'date') {
                this.search_date = "";
                this.search_dateTo = "";
                this.$emit('update', {search_value: this.search_date, operator: this.select_operator})   
            }
            if((this.select_operator === 'betweenincl' || this.select_operator === 'betweenexcl') && this.field.type === 'number') {
                this.search_value = "";
                this.search_valueTo = "";
                this.$emit('update', {search_value: this.search_value + " " + this.search_valueTo, operator: this.select_operator});
            }
            if((this.select_operator === 'betweenincl' || this.select_operator === 'betweenexcl') && this.field.type === 'date') {
                this.search_date = "";
                this.search_dateTo = "";
                this.$emit('update', {search_value: this.search_date + " " + this.search_dateTo, operator: this.select_operator})   
            }
        },
        onInput(event) {                    
            this.menuDatepicker = false;
            this.menuDatepickerTo = false;
            if (!(this.select_field || this.select_operator)) {                
                this.$emit('update', {search_value: "", operator: this.select_operator, editMode: this.editMode})
            }
            if(this.field.type === 'text') {
                this.$emit('update',  {search_value: this.search_value, operator: this.select_operator, editMode: this.editMode})   
            }  
             if(this.field.type === 'list') {
                this.$emit('update',  {search_value: this.search_value, operator: this.select_operator, editMode: this.editMode})   
            }           
            if(this.field.type === 'number') {
                this.$emit('update', {search_value: this.search_value, operator: this.select_operator, editMode: this.editMode})   
            }
            if(this.field.type === 'date') {
                this.$emit('update', {search_value: this.search_date, operator: this.select_operator, editMode: this.editMode})   
            }
            if((this.select_operator === 'betweenincl' || this.select_operator === 'betweenexcl') && this.field.type === 'number') {
                this.$emit('update', {search_value: this.search_value + " " + this.search_valueTo, operator: this.select_operator, editMode: this.editMode});
            }
            if((this.select_operator === 'betweenincl' || this.select_operator === 'betweenexcl') && this.field.type === 'date') {
                this.$emit('update', {search_value: this.search_date + " " + this.search_dateTo, operator: this.select_operator, editMode: this.editMode})   
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    .smoothborder {
        border: 1px lightgray solid;
    }
</style>
