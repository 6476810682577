<template>
    <v-container :fluid="true">
        <v-row>
            <v-col sm="12" offset="0" lg="8" offset-lg="2" >            
                <div class="text-center">
                    <v-avatar size="96" class="mb-4">
                        <img src="@/assets/images/pexels-ge-yonk-1152077.jpg" alt="" />
                    </v-avatar>
                    <h3 class="mb-4">Setup Your Professional Profile</h3>
                    <p>
                        Before proceeding to use the platform as a Professional, you must complete some information
                    </p>
                </div>
                <base-card class="mb-4">                    
                    <v-card-text class="mb-0 pb-0 text-center">
                        <h3>Choose your Category</h3>
                    </v-card-text>  
                    <v-radio-group v-model="professionalType">
                        <v-card-text class="mt-0 pt-0">
                            <div class="eg-todo-item d-flex justify-space-between flex-wrap align-center mb-5- white- pa-4- rounded mt-0 mb-4">
                                <div class="d-flex align-center">
                                    <v-radio                    
                                        class="mt-1"                                                            
                                        :value="'Therapist'"
                                    ></v-radio>
                                    <h6 class="ma-0">
                                        Therapist&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </h6>
                                </div>
                                <div style="width: 60%;">
                                    <span>Select this if you are a massage Therapist and you intend to use the platform to find more clients.</span>
                                </div>
                            </div>
                            <div class="eg-todo-item d-flex justify-space-between flex-wrap align-center mb-5- white- pa-4- rounded">
                                <div class="d-flex align-center">
                                    <v-radio                       
                                        class="mt-1"                                                                             
                                        :value="'Business Owner'"
                                    ></v-radio>
                                    <h6 class="ma-0">
                                        Business Owner
                                    </h6>
                                </div>
                                <div style="width: 60%;">
                                    <span>Select this if you own a Spa or Massage Business, and you intend to connect with Massage Therapists or clients through our platform.</span>
                                </div>
                            </div>
                        </v-card-text>  
                    </v-radio-group>                                    
                </base-card>

                <base-card v-if="professionalType==='Therapist'">                    
                    <v-card-text class="mb-0 pb-0 text-center">
                        <h3>Location</h3>
                        <h5>Select the Cities that you are going to be offering your services.</h5>                        
                    </v-card-text>  

                    <!-- <span>{{JSON.stringify(therapist_location_fieldgroup)}}</span> -->
                    <TherapistLocationSelector v-model="therapist_location_fieldgroup" :prefectures="prefectures" :cities="cities" />                   
                </base-card>

                <base-card class="mb-4"  v-if="professionalType==='Therapist'">                    
                    <v-card-text class="mb-0 pb-0 text-center">
                        <h3>Services & Experience</h3>                        
                    </v-card-text>  
                    <v-card-text class="mt-0 pt-0 mb-0 pb-0">        
                        <TherapistServicesAndExperienceFieldGroup v-model="therapist_svc_fieldgroup" 
                            :largefieldheaders="true" 
                            :occupations="occupations"
                        />                       
                    </v-card-text>                                                       
                </base-card>

                <base-card class="mb-4"  v-if="professionalType==='Therapist'">    
                <v-card-text class="mb-0 pb-0 text-center">
                    <h3>Booking</h3>            
                </v-card-text> 
                    <TherapistBookingFieldGroup v-model="therapist_booking_fieldgroup" />
                    
                    <div class="eg-todo-item d-flex justify-space-between flex-wrap align-center mb-5- white- pa-4- rounded mt-6">
                        <div class="d-flex align-center">
                            <v-checkbox                       
                            ></v-checkbox>
                            <h6 class="ma-0">
                                Accept the Terms & Conditions
                            </h6>
                        </div>                        
                    </div>                          
                </base-card>

                <base-card class="mb-4"  v-if="professionalType==='Therapist'">                      
                    <v-card-text class="mt-0 pt-4 mb-0 pb-4">                                
                        <v-btn class=" text-capitalize"    large                                 
                                block 
                                color="#EB8E45"                                 
                                dark                
                                :loading="false"   
                                :to="'/mng/profileedit'"                                            
                            >
                                <v-icon left>mdi-checkbox-marked-outline</v-icon>
                                Complete
                            </v-btn>   
                    </v-card-text>                                                                         
                </base-card>









                <base-card class="mb-4"  v-if="professionalType==='Business Owner'">                    
                    <v-card-text class="mb-0 pb-0 text-center">
                        <h3>Business Information</h3>
                        <!-- <h6>Select the Cities that you are going to be offering your services.</h6> -->
                        <!-- {{JSON.stringify(pois)}} -->
                    </v-card-text>  
                    <v-card-text class="mt-0 pt-0 mb-0 pb-0">      
                        <div>{{JSON.stringify(businessowner_businessinfo_fieldgroup)}}</div>  
                        <BusinessOwnerBusinessInfoFieldGroup v-model="businessowner_businessinfo_fieldgroup" />                        
                    </v-card-text>                                                       
                </base-card>              


                <base-card class="mb-4"  v-if="professionalType==='Business Owner'">                    
                    <v-card-text class="mb-0 pb-0 text-center">
                        <h3>Location & Contact</h3>
                        <div>DATA: {{JSON.stringify(locations)}}</div>  
                    </v-card-text>  
                    <v-card-text class="mt-0 pt-0 mb-0 pb-4">        
                        <v-row >                            
                            <v-col sm="12" style="vertical-align: middle;">                                
                                <template v-for="(contact, index) in locations">
                                    <div :key="'cont_'+index">                                        
                                        <LocationEditor :key="'cont_'+index" :index="index" v-model="locations[index]" />
                                    </div>                                    
                                </template>                                                                
                                <v-list>                                   
                                    <v-list-item>
                                        <v-list-item-content class="pb-0">                                            
                                            <v-btn class="mb-4 mt-8 text-capitalize"                                 
                                                block 
                                                color="primary"                                 
                                                dark                
                                                :loading="false"  
                                                @click="locations.push(createContactObject())"        
                                                x-large                                    
                                            >
                                                <v-icon left>mdi-plus-outlined</v-icon>
                                                Add Another Location
                                            </v-btn>   
                                            
                                        </v-list-item-content>
                                    </v-list-item>                                   
                                </v-list>                               
                            </v-col>                            
                        </v-row>  
                    </v-card-text>                                                                         
                </base-card>  
                
                
                <base-card class="mb-4"  v-if="professionalType==='Business Owner'">                      
                    <v-card-text class="mt-0 pt-4 mb-0 pb-4">                                
                        <v-btn class=" text-capitalize"    large                                 
                                block 
                                color="#EB8E45"                                 
                                dark                
                                :loading="false"  
                                :to="'/mng/profileedit'"                                            
                            >
                                <v-icon left>mdi-checkbox-marked-outline</v-icon>
                                Complete
                            </v-btn>   
                    </v-card-text>                                                                         
                </base-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {api, srvApi, srvApiNoAuth, PATH} from "src/api/index";
import moment from "moment";
import AdvancedGridFiltersList from "@/components/core/AdvancedGridFiltersList";
import GoogleMap from "@/components/core/GoogleMap";
import LocationEditor from "@/components/home/LocationEditor";
import TherapistServicesAndExperienceFieldGroup from "@/components/home/TherapistServicesAndExperienceFieldGroup";
import TherapistBookingFieldGroup from "@/components/home/TherapistBookingFieldGroup";
import TherapistLocationSelector from "@/components/home/TherapistLocationSelector";
import BusinessOwnerBusinessInfoFieldGroup from "@/components/home/BusinessOwnerBusinessInfoFieldGroup";

import { mapGetters, mapActions } from "vuex";

export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Professional up'
    },    
    components: {
        "advanced-grid-filters-list": AdvancedGridFiltersList ,
        GoogleMap,
        LocationEditor,
        TherapistServicesAndExperienceFieldGroup,
        TherapistBookingFieldGroup,
        TherapistLocationSelector,
        BusinessOwnerBusinessInfoFieldGroup        
    },
    data() {
        return {

            professionalType: null,
            therapist_svc_fieldgroup: {
                experience_years: null,
                experience_months: null,
                expertise: [],                
                caninvoice: null,
                vatnumber: null
            },
            therapist_booking_fieldgroup: {
                bookingFlow: null,
                cancelationFlow: null,
            },
            therapist_location_fieldgroup: {
                selectedCities: [],
                selectedPrefectures: [],
            },
            businessowner_businessinfo_fieldgroup: {
                businessCommercialName: null,
                operatingSinceYear: null,
                ownerfullname: null,
                vatnumber: null,
                noofemployees: null,
                officialservicesdescription: null,
            },            
            acceptterms: null,
            occupations: ['Cosmetology','Physiotherapy','Massage', 'Reflexology'],
                        
            
            
            //THERAPIST FIELDS
            selectedCities: [],
            selectedPrefectures: [],                        
            
            // expertise: [],
            // experience_years: null,
            // experience_months: null,            
            // caninvoice: null,            
            // vatnumber: null,
            // bookingFlow: null,
            // cancelationFlow: null,

            //BUSINESS OWNER FIELDS            
            businessCommercialName: null,
            operatingSinceYear: null,
            ownerfullname: null,
            vatnumber: null,
            noofemployees: null,
            officialservicesdescription: null,
            locations: [this.createContactObject()],



            //REF LISTS
            prefectures: [],
            cities: [],
            nearbyCities: [],
            operationYears: [...Array(40).keys()].map(i=>2023-i),




            totalItemCount: 0,
            quicksearch: '',
            pagingOptions: {},
            selected: [],
            baseData: [],
            select_field: null,
            select_operator: null,
            filtercriteria: null,
                            
            filtersVisible: false,
            headers: [
                {text: 'Name', align: 'start', value: 'fullname',  sortable: false},
                {text: 'Email', align: 'start', value: 'username'},                
                {text: 'Role', value: 'role'},
                // {text: 'Products', value: 'fat'},
                // {text: 'Wallet Balance', value: 'carbs'},
                {text: 'Status', value: 'status',  sortable: false},
                {text: 'Create Date', value: 'aud_create_date'},                
                {text: 'Action', value: 'action'}
            ]
        }
    },
    filters: {
        formatDate(value) {
            return moment.utc(value).format("DD/MM/YYYY");
        }
    },
    watch: {
      pagingOptions: {
        handler () {
          this.fetchGridData();
        },
        deep: true,
      },
      selectedPrefectures() {
        console.log(JSON.stringify(this.selectedPrefectures));
      },
      async selectedCities() {
        //const citiesToSend = this.selectedCities;
        const rsp = await api.hud.nearbycities(this.selectedCities);
        this.nearbyCities.splice(0);
        this.nearbyCities.push(...rsp.data);
      }
    //   quicksearch: function() {
    //       this.fetchGridData();
    //   }
    },
    computed: {
        // gridData() {
        //     const rspData = this.baseData;
        //     return this.formatListData(rspData);
        // },
        filterColumnCount() {
            const d = this.filtersVisible;
            return (d ? 2 : 0);
        },
        gridColumnCount() {
            const d = this.filtersVisible;
            return (d ? 10 : 12);
        },
        shownCities() {
            let self = this;
            let prefs = this.selectedPrefectures.map(i=>{ return this.prefectures[i] }) || [];
            return this.cities.filter(c=> {
                if(prefs && prefs.length > 0) {
                    if(self.quicksearch) {
                        return (c.city.includes(self.quicksearch.toUpperCase())
                        &&
                            !self.selectedCities.includes(c.city) )
                        &&  prefs.includes(c.admin_name)                    
                        ;
                    }
                    else {
                        return !self.selectedCities.includes(c.city) &&  prefs.includes(c.admin_name)   ;
                    } 
                }
                else {
                    if(self.quicksearch) {
                        return (c.city.includes(self.quicksearch.toUpperCase())
                        &&
                            !self.selectedCities.includes(c.city) )                    
                        ;
                    }
                    else {
                        return !self.selectedCities.includes(c.city);
                    } 
                }
                               
            }).slice(0, 20);
        },
        pois() {
            // return [
            //     {position: { lat: 38.03395168814914, lng: 23.74479997873596 }, fillColor: "black" },
            //     {position: { lat: 38.096211703562744, lng: 23.82383093781674 }, fillColor: "black" },
            // ];
            return this.cities.filter(c=> {
                return this.selectedCities.includes(c.city)
            }).map(c=> { return {position: {lat: parseFloat(c.lat), lng: parseFloat(c.lng)}}});
        }
    },
    methods: {
        ...mapActions(["closeVerticalSaasSidebarDrawer", "openVerticalSaasSidebarDrawer"]),
        // formatListData(rspData) {
        //     const d = rspData.map(d => { 
        //         return {
        //             ...d, 
        //             fullname: d.firstName + ' ' + d.lastName,
        //             status: ( (d.disabled) ? "Disabled" :
        //                 (d.invitation_code) ? "Invited" :
        //                 (d.activation_date === "" || d.activation_date == null) ? "Awaiting Activation" : "Active"                         
        //             )
        //         } })
        //     return d;
        // },
        createContactObject() {
            return {
                businessprefecture: null,
                businessmunicipality: null,
                address: null,
                postalcode: null, //"12356",
                phoneno: null,
                email: null,
                latcoord: null, //37.9795244,
                lngcoord: null, //23.7359515
            }
        },
        selectCity(cityToSelect) {
            this.selectedCities.push(cityToSelect);
            this.quicksearch = null;
        },
        deselectCity(cityToDeselect) {
            this.selectedCities.splice(this.selectedCities.indexOf(cityToDeselect),1);
        },
        async fetchGridData() {            
            let qstr = "";
            //paging
            console.log("\nPAGING PAGING PAGING PAGING \n" + JSON.stringify(this.pagingOptions));
            const { sortBy, sortDesc, page, itemsPerPage } = this.pagingOptions;
            const limit = itemsPerPage;
            const currentPage = page;
            qstr += "?currentPage=" + page + "&limit=" + limit;
            //sorting       
            //if(!qstr.includes("?")) qstr = "?" + qstr;
            for(let i=0; i<sortBy.length; i++) {                
                qstr += "&sortBy[" + i + "]=" + sortBy[i];                
            }
            //sorting                   
            for(let i=0; i<sortDesc.length; i++) {                
                qstr += "&sortDesc[" + i + "]=" + sortDesc[i];                
            }     
            //criteria
            //if(!qstr.includes("?")) qstr = "?" + qstr;
            if(this.filtercriteria && this.filtercriteria.qs) {                
                qstr += this.filtercriteria.qs;
            }      
            //quicksearch
            if(this.quicksearch && this.quicksearch.length > 2){
                //if(!qstr.includes("?")) qstr = "?" + qstr;
                qstr += "&quicksearch=" + encodeURIComponent(this.quicksearch);
            }      
            // //alert(querystr);
            // const rsp = await api.users.getList(qstr);
            // this.baseData = rsp.data.data.map(d => { return {...d, fullname: d.firstName + ' ' + d.lastName} }); 
            // this.totalItemCount = rsp.data.total;
        },
        toggleFilterView() {
            this.filtersVisible = ! this.filtersVisible
        },
        onSearch() {
            //alert(JSON.stringify(this.filtercriteria, null, 2));
            //alert(JSON.stringify(this.filtercriteria.qs, null, 2));
            this.fetchGridData();
        },
        triggerQuicksearch() {
            // if (this.quicksearch && this.quicksearch.length > 2) {
            //     this.fetchGridData();
            // }      
            //this.fetchGridData();       
        }
    },
    async created() {
        //this.fetchGridData();
        // const rsp = await api.users.getList("");
        // this.baseData = rsp.data.data.map(d => { return {...d, fullname: d.firstName + ' ' + d.lastName} }); 
        // this.baseData = [{
        //             img: require('@/assets/images/avatars/001-man.svg'),
        //             name: 'Jhon Doe',
        //             calories: 'Walmart',
        //             fat: '95',
        //             carbs: '$14,384',
        //             protein: '03/24/2020',
        //             badge: 'Active'
        //         },
        //         {
        //             img: require('@/assets/images/avatars/002-woman.svg'),
        //             name: 'Elizabeth Doe',
        //             calories: 'KFC',
        //             fat: '876',
        //             carbs: '$38,384',
        //             protein: '03/24/2020',
        //             badge: 'Deactive'
        //         },]
        //console.log("DBGINDICATOR_ " + JSON.stringify(XYZ, null, ''));
    },
    async mounted() {                        

        const rsp = await api.hud.myhomepageinfo();        
        if(JSON.parse(localStorage.getItem("userInfo")).user.role !== "customer") {
            this.openVerticalSaasSidebarDrawer();
        }
        this.prefectures.push(...rsp.data.prefectures);
        this.cities.push(...rsp.data.areas);
    }
}
</script>
<style lang="scss" scoped>
::v-deep .table-one {
    thead.v-data-table-header {
        tr {
            &:hover {
                background-color: #f2f3f8;
            }
            th {
                span {
                    font-size: 16px;
                    color: #304156;
                }
            }
        }
        tr {
            td {
                padding-bottom: 20px;
                padding-top: 20px;
            }
        }
    }
    tbody {
        tr {
            &:hover {
                background-color: #f2f3f8 !important;
            }
        }
    }
}
</style>
