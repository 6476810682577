<template>
    <v-row >

        <v-col cols="12" sm="12" class="pt-5 pl-6">
            <h5 v-if="largefieldheaders">Enter the duration of your work experience:</h5>
            <h6 v-if="!largefieldheaders">Enter the duration of your work experience:</h6>
        </v-col>
        <v-col  cols="12" sm="12" style="vertical-align: middle;">
            <v-list>
                <v-list-item>
                    <v-list-item-content class="pb-0">
                        <v-select                                                                     
                            v-model="value.experience_years"
                            :items="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40]"                 
                            label="Years" 
                            class="col-3 p-0 pr-4"
                            style="padding: 0px 0px; max-width: 100px;"
                            :rules="[]"     
                            required                                                         
                            :clearable="true"                      
                        />
                        <span class="col-1 p-0">{{ $t("gen.and") }}</span>
                        <v-select                                                                     
                            v-model="value.experience_months"
                            :items="[1,2,3,4,5,6,7,8,9,10,11]"                 
                            label="Months" 
                            class="col-3 p-0 pr-4"
                            style="padding: 0px 0px; max-width: 100px;"
                            :rules="[]"     
                            required                                                         
                            :clearable="true"                      
                        />                                            
                    </v-list-item-content>
                </v-list-item>
            </v-list>                
        </v-col>
        <v-col cols="12"  sm="12" class="pt-15 pl-6 pb-0 mb-0">
            <h5 v-if="largefieldheaders">What is your occupation/expertise: </h5>
            <h6 v-if="!largefieldheaders">What is your occupation/expertise: </h6>
        </v-col>
        <v-col cols="12"  sm="12" class="pt-0 mt-0" style="vertical-align: middle;">
            <v-item-group active-class="primary" multiple v-model="value.expertise">
                            <v-container>
                            <v-row>
                                <v-col
                                v-for="n in occupations"
                                :key="n.value"
                                cols="12"
                                md="4"
                                >
                                <v-item v-slot="{ active, toggle }" :key="n.value" :value="n.value"
                                >
                                    <v-card
                                    class="d-flex align-center"                                                        
                                    height="100"
                                    @click="toggle"
                                    :style="{'background-color': active ? '#EB8E45 !important': 'white', 'opacity': 0.8}"
                                    >
                                    <v-scroll-y-transition>
                                        <div
                                        v-if="active"
                                        class="text-h5 flex-grow-1 text-center"
                                        style="color: white"                                                            
                                        >
                                        {{n.text}}
                                        </div>
                                        <div
                                        v-else
                                        class="text-h5 flex-grow-1 text-center"                                                            
                                        >
                                        {{n.text}}
                                        </div>
                                    </v-scroll-y-transition>
                                    </v-card>
                                </v-item>
                                </v-col>
                            </v-row>
                            </v-container>
                        </v-item-group>                              
        </v-col>
        <!--<v-col cols="12"  sm="12" class="pt-15 pl-6 pb-0 mb-0">
            <h5 v-if="largefieldheaders">Can you provide an invoice for your services?</h5>
            <h6 v-if="!largefieldheaders">Can you provide an invoice for your services?</h6>
        </v-col>
        <v-col cols="12"  sm="12" class="pt-0 mt-0" style="vertical-align: middle;">
            <v-list>
                <v-list-item>
                    <v-list-item-content class="pb-0">
                        <v-select         
                            placeholder="yes/no"                                                            
                            v-model="value.caninvoice"
                            :items="['yes', 'no']"                 
                            label="" 
                            class="col-4 p-0 pr-4"
                            style="padding: 0px 0px;"
                            :rules="[]"     
                            required                                                         
                            :clearable="true"                      
                        />                                           
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-col>-->
        <v-col cols="12"  sm="12" class="pt-5 pl-6 pb-0 mb-0">
            <h5 v-if="largefieldheaders">Enter your VAT Number.</h5>
            <h6 v-if="!largefieldheaders">Enter your VAT Number.</h6>
        </v-col>
        <v-col cols="12"  sm="12" class="pt-0 mt-0" style="vertical-align: middle;">
            <v-list>
                <v-list-item>
                    <v-list-item-content class="pb-0">
                        <v-text-field 
                            label="VAT Number" 
                            v-model="value.vatnumber"    
                            :rules="[]"                                                
                            class="col-6 p-0 pr-4"
                            style="padding: 0px 0px;"
                        />                                         
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-col>
    </v-row> 
</template>
<script>
export default {
    model: {
        prop: "value",
        event: "change"
    },

    props: {
        occupations: {
            type: Array,
            default: () => []
        },
        /**
         * The color for the button.
         */
        largefieldheaders: {
            type: Boolean,
            default: () => false,
            required: false
        },
        value: {
            type: Object,
            default: () => ({
                experience_years: undefined,
                experience_months: undefined,
                expertise: undefined,                
                caninvoice: undefined,
                vatnumber: undefined
            }),
        },
    },

    methods: {
        emit() {
            this.$emit('change', this.value);
        }
    },
}
</script>
