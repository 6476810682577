<template>
    <div :style="fullheight ? 'height: 800px; padding-bottom: 80px;' : 'height: 300px; padding-bottom: 80px;' ">
        <input ref="autocomplete" id="autocomplete" placeholder="Search Address" style="font-size: 1.5em; height: 2.5em; border-radius: 15px;" />
        <gmaps-map @mounted="ready" :options="mapOptions">
            <gmaps-marker 
              :position="markerPos" 
              :options="markerOptions"
              @dragend="markerdrag"
            />
        </gmaps-map>      
    </div>
</template>
<script>
import { gmaps, gmapsMap, gmapsMarker } from 'x5-gmaps'
export default {
  components: { gmapsMap, gmapsMarker },

  props: {   
      fullheight: {
            type: Boolean,
            default: () => false,
        },     
        pinCoords: {
            type: Object,
            default: () => ({
                lat: undefined,
                lng: undefined
            }),
        },
    },

  data: () => ({
    autocomplete: null,
    places: null,
    map: null,
    markerPos: {lat: 28, lng: 24}, //{ lat: (this.pinCoords && this.pinCoords.lat ? this.pinCoords.lat : 38), lng: (this.pinCoords && this.pinCoords.lng ? this.pinCoords.lng : 24) }, //, 
    resultInfo: null,

    markerOptions: {
      draggable: true,
      icon: require("@/assets/images/massage_marker2.png")
    },

    mapOptions: {
      center: { lat: 37.9842, lng: 23.7281 },
      zoom: 16,      
      language: "gr"
    }
  }),
  methods: {
    ready(map) {
      this.map = map
      gmaps().then((maps) => {
        this.places = new maps.places.PlacesService(map);
        this.autocomplete = new maps.places.Autocomplete(this.$refs.autocomplete);
        this.autocomplete.addListener('place_changed', this.searchResultSelected);

      })
    },
    searchResultSelected() {
        const place = this.autocomplete.getPlace()
        if (place.geometry) {
            this.map.panTo(place.geometry.location)
            this.markerPos = place.geometry.location
        }       
        let rslt = this.extractAddress(place);
        this.$emit('onresult', rslt); 
        this.resultInfo = rslt;
    },

    extractAddress(placeResult) {    
      let addressComponents = placeResult.address_components;
      let rslt = {};      
      if(placeResult.address_components) {
        rslt['streetaddress']= this.extractPropertyFromGeocodingResults(addressComponents, "route") + " " + this.extractPropertyFromGeocodingResults(addressComponents, "street_number");
        rslt['municipality']= this.extractPropertyFromGeocodingResults(addressComponents, "locality");
        rslt['postalcode']= this.extractPropertyFromGeocodingResults(addressComponents, "postal_code");
        rslt['prefecture']= this.extractPropertyFromGeocodingResults(addressComponents, "administrative_area_level_3");
        rslt['country']= this.extractPropertyFromGeocodingResults(addressComponents, "country");
      }            
      if(placeResult.geometry) {
        rslt['lat']= placeResult.geometry.location.lat();
        rslt['lng']= placeResult.geometry.location.lng();
      }            
      return rslt;      
    },

    extractPropertyFromGeocodingResults(address_components, propName, longname=false) {
      let element = address_components.find(c=>c.types.includes(propName));
      if(element) {
          if(longname) {
              return element.long_name;                    
          }
          else {
              return element.short_name;
          }                
      }
      return "";
    },

    markerdrag(coords) {      
      
      // let rslt = {};
      // rslt['lat'] = coords.latLng.lat;
      // rslt['lng'] = coords.latLng.lng;
      // this.$emit('onresult', rslt);
      this.$emit('onresult', {lat: coords.latLng.lat(), lng: coords.latLng.lng()});
    }
  },

  mounted() {
    if(this.pinCoords.lat && this.pinCoords.lng) {;
      //alert("ENTERED PINCOORDS: " + JSON.stringify(this.pinCoords))
      this.markerPos.lat = this.pinCoords.lat;
      this.markerPos.lng = this.pinCoords.lng;
      this.mapOptions.center.lat = this.pinCoords.lat;
      this.mapOptions.center.lng = this.pinCoords.lng;
    }
  }
}
</script>
<style scoped>
    #autocomplete {
        border: 3px solid gray;
        border-radius: 5px;
        font-size: 18px;
        height: 40px;
        left: 15%;
        padding: 0 10px;
        position: relative;
        clear: both;
        top: 0;
        width: 70%;
        z-index: 1;
        margin-bottom: 15px;
    }
</style>