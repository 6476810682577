<template>
  <v-row >                            
    <v-col sm="12" style="vertical-align: middle;">
        <v-list>
            <v-list-item>
                <v-list-item-content class="pb-0">                                            
                    <v-text-field 
                        label="Business Commercial Name" 
                        v-model="value.businessCommercialName"    
                        :rules="[]"
                        :validate-on-blur="false"
                        class="col-6 p-0 pr-4"
                        style="padding: 0px 0px;"
                    />
                    <v-select                                                                     
                        v-model="value.operatingSinceYear"
                        :items="operationYears"                 
                        label="Operating Since (Year)" 
                        class="col-4 p-0 pr-4"
                        style="padding: 0px 0px;"
                        :rules="[]"     
                        required                                                         
                        :clearable="true"                      
                    />
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content class="pb-0">                                            
                    <v-text-field 
                        label="Owner Fullname" 
                        v-model="value.ownerfullname"    
                        :rules="[]"
                        :validate-on-blur="false"
                        class="col-6 p-0 pr-4"
                        style="padding: 0px 0px;"
                    />                                   
                    <v-text-field 
                        label="VAT Number" 
                        v-model="value.vatnumber"    
                        :rules="[]"
                        :validate-on-blur="false"
                        class="col-4 p-0 pr-4"
                        style="padding: 0px 0px;"
                    />
                    
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content class="pb-0">
                    <v-select                                                                     
                        v-model="value.noofemployees"
                        :items='["1-5","6-10","11-20", "21-40", "41+"]'                 
                        label="Number of Employees" 
                        class="col-4 p-0 pr-4"
                        style="padding: 0px 0px;"
                        :rules="[]"     
                        required                                                         
                        :clearable="true"                      
                    />                                            
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content class="pb-0">
                    <v-textarea
                        label="Official Description of Registered Services" 
                        v-model="value.officialservicesdescription"    
                        :rules="[]"
                        :validate-on-blur="false"
                        class="col-12 p-0 pr-4"
                        style="padding: 0px 0px;"
                    />                                           
                </v-list-item-content>
            </v-list-item>
        </v-list>                                
    </v-col>                            
</v-row> 
</template>

<script>
export default {
    model: {
        prop: "value",
        event: "change"
    },

    data() {
        return {
            operationYears: [...Array(40).keys()].map(i=>2023-i),
        }
    },
    props: {
        value: {
            type: Object,
            default: () => ({
                businessCommercialName: undefined,
                operatingSinceYear: undefined,
                ownerfullname: undefined,
                vatnumber: undefined,
                noofemployees: undefined,
                officialservicesdescription: undefined,
            }),
        },
    },

    methods: {
        emit() {
            this.$emit('change', this.value);
        }
    },
}
</script>

<style>

</style>